export default {
	index: "/",

	auth: {
		login: "/auth/login",
		logout: "/auth/logout",
	},

	admin: {
		index: "/admin",
		login: "/admin/login",
		profile: "/admin/profile",
		posts: {
			index: "/admin/posts",
			create: "/admin/posts/create",
			edit: "/admin/posts/:id",
		},
		categories: {
			index: "/admin/categories",
			create: "/admin/categories/create",
			edit: "/admin/categories/:id",
		},
		users: {
			index: "/admin/users",
			edit: "/admin/users/:id",
		},
		postLikes: {
			index: "/admin/post-likes",
			edit: "/admin/post-likes/:id",
		},
		postResponses: {
			index: "/admin/post-responses",
			edit: "/admin/post-responses/:id",
		},
		postShares: {
			index: "/admin/post-shares",
			edit: "/admin/post-shares/:id",
		},
		postViews: {
			index: "/admin/post-views",
			edit: "/admin/post-views/:id",
		},
		videos: {
			index: "/admin/videos",
		},
		tokens: {
			index: "/admin/tokens",
			edit: "/admin/tokens/:id",
		},
	},
} as const;
